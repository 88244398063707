import React, { useRef, useEffect, useState } from "react";
import "./../styles/Home.css";

const Home = ({ onLogin }) => {
    const videoRef = useRef(null);
    const audioRef = useRef(null);
    const [isAudioPlaying, setIsAudioPlaying] = useState(false); // Track audio state

    const handleLogin = () => {
        window.location.href = "http://localhost:5000/auth/roblox"; // Backend login endpoint
    };

    const handleAudioToggle = () => {
        const audio = audioRef.current;
        if (audio) {
            if (isAudioPlaying) {
                audio.pause();
                setIsAudioPlaying(false);
            } else {
                audio
                    .play()
                    .then(() => {
                        setIsAudioPlaying(true); // Update state to reflect audio is playing
                    })
                    .catch((err) => {
                        console.warn("Audio playback failed:", err.message);
                    });
            }
        }
    };

    useEffect(() => {
        const video = videoRef.current;

        const handleTimeUpdate = () => {
            // Fade out near the end of the video
            if (video.duration - video.currentTime < 1) {
                video.style.opacity = 0.6; // Slight fade-out effect
            } else {
                video.style.opacity = 1; // Reset opacity
            }
        };

        video.addEventListener("timeupdate", handleTimeUpdate);

        return () => {
            video.removeEventListener("timeupdate", handleTimeUpdate);
        };
    }, []);

    return (
        <div className="home-container">
            {/* Video Background */}
            <div className="video-wrapper">
                <video
                    ref={videoRef}
                    className="background-video"
                    autoPlay
                    loop
                    muted
                    playsInline
                    src="https://firebasestorage.googleapis.com/v0/b/romax-d8852.firebasestorage.app/o/Giga%20Chad%20but%20in%20Roblox_720.mp4?alt=media&token=ee11cbbf-710c-49e4-98f6-cd33c5804a5b"
                />
            </div>

            {/* Audio Element */}
            <audio
                ref={audioRef}
                loop
                src="https://firebasestorage.googleapis.com/v0/b/romax-d8852.firebasestorage.app/o/sigma.mp3?alt=media&token=3afca905-8336-4a93-b07f-4e473c110bc7"
            ></audio>

            {/* Audio Control Icon */}
            <div className="audio-icon" onClick={handleAudioToggle}>
                {isAudioPlaying ? (
                    <span>&#x1F507; {/* Muted symbol */}</span>
                ) : (
                    <span>&#x1F3B5; {/* Music note symbol */}</span>
                )}
            </div>

            {/* Welcome Text */}
            <h1 className="home-title">
                Welcome to Ro<span className="gradient-text">MAX</span>
            </h1>
            <p className="home-subtitle">Unleash your looksmax potential with RoMAX!</p>
            <button className="home-login-button" onClick={handleLogin}>
                Login with Roblox
            </button>
        </div>
    );
};

export default Home;
