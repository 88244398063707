import React, { useEffect, useState } from "react";
import "../styles/Dashboard.css"; // Importing the styles
import CircularMeter from "../components/CircularMeter";
import axios from "axios";

const Dashboard = ({ user }) => {
    const [inventory, setInventory] = useState([]); // Initialize as an empty array
    const [score, setScore] = useState(0);
    const [loading, setLoading] = useState(true); // Start loading as true
    const [isInitialized, setIsInitialized] = useState(false);
    const [userInfo, setUserInfo] = useState(null);

    const fetchFirestoreData = async () => {
        try {
            console.log("Fetching user data from Firestore...");
            const response = await axios.get(`http://localhost:5000/roblox-userinfo?userId=${user.uid}`);
            const userData = response.data;

            console.log("Fetched Firestore Data:", userData);

            // Set user info
            setUserInfo({
                nickname: userData.username || "Roblox User",
                picture: userData.profilePicture,
            });

            // Set score
            setScore(userData.points || 0);

            // Set inventory categories
            if (userData.inventory) {
                const { assets = [], badges = [], gamePasses = [] } = userData.inventory;
                setInventory({
                    assets,
                    badges,
                    gamePasses,
                });
            } else {
                console.warn("No inventory found for user.");
                setInventory({
                    assets: [],
                    badges: [],
                    gamePasses: [],
                });
            }

            // Check initialization status
            if (userData.isInitialized) {
                console.log("User is already initialized.");
                setIsInitialized(true);
            } else {
                console.log("User not initialized. Initializing...");
                const initResponse = await axios.post(`http://localhost:5000/initialize-user`, {
                    userId: user.uid,
                });

                if (initResponse.data.success) {
                    console.log("User initialized successfully.");
                    setIsInitialized(true);

                    // Fetch updated inventory after initialization
                    const updatedResponse = await axios.get(`http://localhost:5000/roblox-userinfo?userId=${user.uid}`);
                    const updatedData = updatedResponse.data;

                    if (updatedData.inventory) {
                        const { assets = [], badges = [], gamePasses = [] } = updatedData.inventory;
                        setInventory({
                            assets,
                            badges,
                            gamePasses,
                        });
                    }
                }
            }
        } catch (error) {
            console.error("Error fetching Firestore data:", error.message);
        } finally {
            setLoading(false); // Stop loading after data is fetched
        }
    };


    useEffect(() => {
        if (user) {
            fetchFirestoreData();
        }
    }, [user]);

    return (
        <div className="dashboard">
            <main className="dashboard-main">
                <h1 className="dashboard-greeting">Welcome, {userInfo?.nickname || "Roblox User"}!</h1>
                {loading ? (
                    <div className="meter-placeholder">
                        <div className="spinner"></div>
                        <p>Loading...</p>
                    </div>
                ) : (
                    <>
                        <CircularMeter score={score} picture={userInfo?.picture} />
                        <p className="dashboard-score">
                            Ro<span className="gradient-text">MAX</span> Score: {score}
                        </p>
                        <p className="dashboard-inventory">
                            You currently own:
                            <br />
                            {inventory.assets?.length || 0} Assets
                            <br />
                            {inventory.badges?.length || 0} Badges
                            <br />
                            {inventory.gamePasses?.length || 0} Game Passes
                        </p>
                    </>
                )}
            </main>
        </div>
    );


};

export default Dashboard;
