import React, { useEffect, useRef, useState } from "react";
import "../styles/CircularMeter.css";

const CircularMeter = ({ score, picture }) => {
    const circleRefs = useRef([]); // Array of refs for overlaying circles
    const [colors, setColors] = useState([]); // Dynamic colors for each spin

    useEffect(() => {
        const segments = Math.ceil(score / 500); // Each segment represents 500 points
        const progressPerSegment = 100; // Full circle per segment
        const maxCircumference = 440; // Full circle circumference

        // Generate a new color for each segment
        const generateColor = () => `hsl(${Math.random() * 360}, 80%, 60%)`;

        // Initialize animation
        let currentSegment = 0;

        const animateSegment = () => {
            if (currentSegment >= segments || !circleRefs.current[currentSegment]) return;

            const circle = circleRefs.current[currentSegment];
            let animationValue = 0;

            const animate = () => {
                animationValue += 2; // Smooth progress, adjust speed if necessary
                if (animationValue > progressPerSegment) {
                    animationValue = progressPerSegment;
                }

                const offset = maxCircumference - (maxCircumference * animationValue) / 100;
                circle.style.strokeDashoffset = offset;

                if (animationValue < progressPerSegment) {
                    requestAnimationFrame(animate);
                } else {
                    currentSegment++;
                    animateSegment(); // Move to the next segment
                }
            };

            animate();
        };

        // Generate colors for the required segments
        const generatedColors = Array(segments)
            .fill(null)
            .map(() => generateColor());
        setColors(generatedColors);

        // Delay animation to ensure refs are attached
        setTimeout(() => {
            animateSegment();
        }, 0);
    }, [score]);

    return (
        <div className="circular-meter-container">
            <svg className="circular-chart" viewBox="0 0 160 160">
                <circle className="circle-bg" cx="80" cy="80" r="70" />
                {colors.map((color, index) => (
                    <circle
                        key={index}
                        ref={(el) => (circleRefs.current[index] = el)}
                        className="circle"
                        cx="80"
                        cy="80"
                        r="70"
                        style={{
                            stroke: color,
                            strokeDasharray: 440,
                            strokeDashoffset: 440, // Start empty
                        }}
                    />
                ))}
            </svg>
            <div className="profile-picture-container">
                {picture && <img src={picture} alt="User Avatar" className="profile-picture" />}
            </div>
        </div>
    );
};

export default CircularMeter;
