// Import the functions you need from the Firebase SDKs
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAdNC9d_GH2aljJXpJhVSxZo3C81PUihlw",
    authDomain: "romax-d8852.firebaseapp.com",
    projectId: "romax-d8852",
    storageBucket: "romax-d8852.appspot.com", // Corrected to use the default ".appspot.com" for storage
    messagingSenderId: "489721033592",
    appId: "1:489721033592:web:ae75f95e3c251491974e8d",
    measurementId: "G-9QL8CNP09B",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Analytics
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and export it
export const auth = getAuth(app);
