import React, { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Navbar from "./components/Navbar";
import Leaderboard from "./pages/Leaderboard"; // Import Leaderboard component
import { auth } from "./firebase"; // Import initialized auth from firebase.js
import { signInWithCustomToken } from "firebase/auth"; // Import necessary function

const App = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state

    // Authenticate user with Firebase Custom Token
    const authenticateWithFirebase = async (firebaseToken) => {
        try {
            console.log("Authenticating with Firebase Token:", firebaseToken);
            const userCredential = await signInWithCustomToken(auth, firebaseToken);
            console.log("User authenticated successfully:", userCredential.user);
            setUser(userCredential.user); // Set the user state
        } catch (error) {
            console.error("Error authenticating with Firebase:", error.message);
        } finally {
            setLoading(false); // End the loading state
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const firebaseToken = urlParams.get("firebaseToken");

        if (firebaseToken) {
            console.log("Firebase Token from URL:", firebaseToken);

            // Clear the query params from the URL
            window.history.replaceState({}, document.title, window.location.pathname);

            // Authenticate with Firebase
            authenticateWithFirebase(firebaseToken);
        } else {
            console.log("No Firebase Token found in URL.");
            setLoading(false); // End loading if no token is found
        }
    }, []);

    const handleLogout = () => {
        auth.signOut()
            .then(() => {
                setUser(null);
                console.log("User logged out successfully.");
            })
            .catch((error) => {
                console.error("Error during logout:", error.message);
            });
    };

    if (loading) {
        return <div style={{ textAlign: "center", marginTop: "50px" }}>Loading...</div>;
    }

    return (
        <Router>
            <AppWithScrollControl user={user} onLogout={handleLogout} />
        </Router>
    );
};

const AppWithScrollControl = ({ user, onLogout }) => {
    const location = useLocation();

    // Disable scrolling only on the homepage
    useEffect(() => {
        if (location.pathname === "/") {
            document.body.style.overflow = "hidden"; // Disable scrolling
        } else {
            document.body.style.overflow = "auto"; // Enable scrolling
        }

        return () => {
            document.body.style.overflow = "auto"; // Reset when component unmounts
        };
    }, [location]);

    return (
        <>
            <Navbar user={user} onLogout={onLogout} />
            <Routes>
                {/* Redirect unauthenticated users to login */}
                <Route path="/" element={user ? <Navigate to="/dashboard" /> : <Home />} />

                {/* Dashboard route is protected */}
                <Route
                    path="/dashboard"
                    element={
                        user ? (
                            <Dashboard
                                user={user}
                                onLogout={onLogout}
                                fetchInventory={async () => 50} // Mock fetchInventory for now
                            />
                        ) : (
                            <Navigate to="/" />
                        )
                    }
                />

                {/* Leaderboard route */}
                <Route
                    path="/leaderboard"
                    element={user ? <Leaderboard /> : <Navigate to="/" />}
                />

                {/* Fallback for invalid paths */}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </>
    );
};

export default App;
