import React from "react";
import { NavLink, useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import "./../styles/Navbar.css";

const Navbar = ({ user, onLogout }) => {
    const navigate = useNavigate(); // Initialize navigate hook

    const handleLogout = () => {
        if (onLogout) {
            onLogout(); // Call the passed logout function
        }
        navigate("/"); // Redirect to the homepage after logout
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <NavLink to="/" className="navbar-home-link">
                    Ro<span className="gradient-text">MAX</span>
                </NavLink>
            </div>

            <div className="navbar-links">
                {user ? (
                    <>
                        <NavLink to="/dashboard" className="navbar-link">
                            Dashboard
                        </NavLink>
                        <NavLink to="/leaderboard" className="navbar-link">
                            Leaderboard
                        </NavLink>
                        <NavLink to="#" onClick={handleLogout} className="navbar-link">
                            Logout
                        </NavLink>
                    </>
                ) : null}
            </div>
        </nav>
    );
};

export default Navbar;
